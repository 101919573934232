import { AsyncPipe, NgClass } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { amlDeskRoutes } from '@core/route-map';
import { Observable } from 'rxjs';
import { LinkService } from '@/shared/services/link-product-based.service';
import { LinkComponent } from '../../elements/link/link.component';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  imports: [LinkComponent, NgClass, AsyncPipe],
})
export class LogoComponent {
  @Input()
  mini = false;

  @Input()
  link = `/${amlDeskRoutes.dashboard}`;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('class')
  @HostBinding('class')
  classList = '';

  link$: Observable<string>;

  constructor(private readonly linkService: LinkService) {
    this.link$ = this.linkService.getLink();
  }
}
