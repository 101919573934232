{
  "name": "cop-app",
  "version": "0.19.0-rc.3",
  "type": "module",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "watch": "ng build --watch --configuration development",
    "test": "jest",
    "test:coverage": "jest --coverage",
    "test:ci": "jest --coverage",
    "build:ci": "cross-env NODE_ENV=$ANGULAR_CONFIGURATION ng build --configuration $ANGULAR_CONFIGURATION",
    "build:dev": "ng build --output-hashing all --configuration development",
    "build:prod:stats": "cross-env NODE_ENV=production ng build --configuration production --stats-json",
    "analyze": "webpack-bundle-analyzer ./dist/stats.json",
    "build:analyze": "npm run build:prod:stats && npm run analyze",
    "prettier-all": "prettier --config .prettierrc.json --ignore-path .prettierignore --write '**/*.{js,ts,json,html,css,scss,md}'",
    "lint": "eslint src/**/*.ts src/**/*.component.html",
    "type:check": "tsc --noEmit",
    "e2e": "ng e2e",
    "docs:json": "compodoc -p ./tsconfig.json -e json -d .",
    "storybook": "ng run cop-app:storybook",
    "build-storybook": "ng run cop-app:build-storybook"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.0.2",
    "@angular/cdk": "19.0.1",
    "@angular/common": "19.0.2",
    "@angular/compiler": "19.0.2",
    "@angular/core": "19.0.2",
    "@angular/forms": "19.0.2",
    "@angular/material": "19.0.1",
    "@angular/platform-browser": "19.0.2",
    "@angular/platform-browser-dynamic": "19.0.2",
    "@angular/router": "19.0.2",
    "@azure/msal-angular": "3.0.11",
    "@azure/msal-browser": "3.7.1",
    "@azure/msal-common": "14.6.1",
    "@bluehalo/ngx-leaflet": "18.0.2",
    "@jsverse/transloco": "7.5.0",
    "@kerberos-compliance/aml-elements-lib": "0.3.91-beta-3",
    "@kerberos-compliance/lib-adp-shared": "0.0.5",
    "@ngrx/data": "18.1.1",
    "@ngrx/effects": "18.1.1",
    "@ngrx/entity": "18.1.1",
    "@ngrx/operators": "18.0.0",
    "@ngrx/store": "18.1.1",
    "@ngrx/store-devtools": "18.1.1",
    "@ngx-formly/bootstrap": "6.3.6",
    "@ngx-formly/core": "6.3.6",
    "@sentry/angular": "8.42.0",
    "@storybook/addon-actions": "~8.4.6",
    "@storybook/angular": "~8.4.6",
    "@tailwindcss/line-clamp": "~0.2.2",
    "bootstrap": "5.3.3",
    "daisyui": "~4.12.14",
    "dayjs": "1.11.11",
    "dotenv": "^16.4.5",
    "klaro": "~0.7.18",
    "leaflet": "1.9.4",
    "ng-dynamic-component": "10.7.0",
    "rxfire": "~6.0.5",
    "rxjs": "~7.8.1",
    "ts-node": "10.9.2",
    "tslib": "~2.6.2",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.3/xlsx-0.20.3.tgz",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-builders/custom-webpack": "18.0.0",
    "@angular-devkit/architect": "0.1900.3",
    "@angular-devkit/build-angular": "19.0.3",
    "@angular-eslint/builder": "16.3.1",
    "@angular/cli": "19.0.3",
    "@angular/compiler-cli": "19.0.2",
    "@angular/elements": "19.0.2",
    "@babel/core": "^7.14.8",
    "@commitlint/cli": "~19.3.0",
    "@commitlint/config-conventional": "~19.2.2",
    "@compodoc/compodoc": "^1.1.19",
    "@kerberos-compliance/ts-tools-configs": "2.2.1",
    "@playwright/test": "1.48.2",
    "@ngrx/schematics": "~18.1.1",
    "@semantic-release/changelog": "^6.0.3",
    "@semantic-release/git": "^10.0.1",
    "@storybook/addon-essentials": "~8.4.4",
    "@storybook/addon-links": "~8.4.4",
    "@types/jest": "^29.5.10",
    "@types/leaflet": "^1.9.14",
    "@types/node": "~18.15.3",
    "@typescript-eslint/eslint-plugin": "~7.11.0",
    "@typescript-eslint/parser": "~7.11.0",
    "@webcomponents/custom-elements": "^1.4.3",
    "autoprefixer": "^10.2.6",
    "babel-loader": "^8.2.5",
    "compression-webpack-plugin": "^9.2.0",
    "cross-env": "^7.0.3",
    "eslint": "~8.57.0",
    "eslint-import-resolver-node": "~0.3.9",
    "eslint-import-resolver-typescript": "~3.6.1",
    "eslint-plugin-storybook": "~0.11.1",
    "fuzzy": "^0.1.3",
    "husky": "~8.0.3",
    "identity-obj-proxy": "^3.0.0",
    "inquirer": "^6.2.2",
    "inquirer-autocomplete-prompt": "^1.0.2",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-junit": "^16.0.0",
    "jest-preset-angular": "~14.4.2",
    "jest-sonar": "^0.2.16",
    "jsonc-parser": "^3.0.0",
    "lint-staged": "~15.2.5",
    "ng-mocks": "^14.12.2",
    "npm-check-updates": "~16.14.20",
    "open": "^7.0.4",
    "playwright-ng-schematics": "1.1.1",
    "postcss": "^8.2.15",
    "prettier": "~3.2.5",
    "pretty-quick": "^4.0.0",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "semantic-release": "^22.0.12",
    "storybook": "~8.4.4",
    "storybook-addon-angular-router": "~1.10.1",
    "tailwindcss": "3.4.15",
    "ts-jest": "^29.1.1",
    "typescript": "~5.6.3",
    "webpack-bundle-analyzer": "4.5.0"
  },
  "release": {
    "branches": [
      {
        "name": "main"
      },
      {
        "name": "release\\/*",
        "channel": "pre/rc",
        "prerelease": "rc"
      }
    ],
    "plugins": [
      "@semantic-release/commit-analyzer",
      "@semantic-release/release-notes-generator",
      "@semantic-release/npm",
      [
        "@semantic-release/changelog",
        {
          "changelogFile": "docs/CHANGELOG.md"
        }
      ],
      [
        "@semantic-release/git",
        {
          "assets": [
            "dist/**/*.{js,css}",
            "docs/*",
            "package.json"
          ],
          "message": "chore(release): ${nextRelease.version}\n\n${nextRelease.notes}"
        }
      ]
    ]
  },
  "engines": {
    "node": ">=20.0.0"
  },
  "packageManager": "pnpm@9.15.0+sha512.76e2379760a4328ec4415815bcd6628dee727af3779aaa4c914e3944156c4299921a89f976381ee107d41f12cfa4b66681ca9c718f0668fa0831ed4c6d8ba56c"
}
