import { AsyncPipe, DOCUMENT, NgClass } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { selectIsLg } from '@app/store/selectors/screen-size.selectors';
import { NavComponent } from '@core/components/layout/nav/nav.component';
import { NoConnectionComponent } from '@core/components/no-connection/components/no-connection.component';
import { ConnectionStatusService } from '@core/components/no-connection/services/connection-status.service';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { LogoComponent } from '@/shared/components/fragments/logo/logo.component';
import { NavActions } from '@/shared/store/actions/nav.actions';
import { selectIsOpened } from '@/shared/store/selectors/nav.selectors';
import packageJson from '../../../../../../package.json';

@Component({
  selector: 'app-navbar-container',
  templateUrl: './navbar-container.component.html',
  styleUrls: ['./navbar-container.component.scss'],
  imports: [MatSidenavModule, AsyncPipe, NgClass, MatIconModule, LogoComponent, NavComponent, NoConnectionComponent],
})
export class NavbarContainerComponent implements OnInit {
  open$: Observable<boolean> = this.store.select(selectIsOpened);

  isLarge$: Observable<boolean> = this.store.select(selectIsLg);

  version: string = packageJson.version;

  readonly versionVisibility = environment.productionBuild;

  readonly isConnected$ = this.connectionStatus.connectionStatus$;

  constructor(
    private readonly store: Store,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document,
    private connectionStatus: ConnectionStatusService,
  ) {}

  ngOnInit(): void {
    this.open$.pipe(tap(() => this.toggleBodyScroll())).subscribe();
  }

  closeSideNav(): void {
    this.store.dispatch(NavActions.toggleClose());
  }

  openSideNav(): void {
    this.store.dispatch(NavActions.toggleOpen());
  }

  toggle(invertCondition: boolean = true): void {
    this.open$
      .pipe(
        take(1),
        tap((open) => {
          const shouldOpen = invertCondition ? !open : open;
          if (shouldOpen) {
            this.closeSideNav();
          } else {
            this.openSideNav();
          }
        }),
      )
      .subscribe();
  }

  toggleBodyScroll(): void {
    this.open$
      .pipe(
        take(1),
        tap((open) => {
          if (open) {
            this.renderer.addClass(this.document.body, 'overflow-hidden');
          } else {
            this.renderer.removeClass(this.document.body, 'overflow-hidden');
          }
        }),
      )
      .subscribe();
  }

  navItemClicked(): void {
    combineLatest([this.open$, this.isLarge$])
      .pipe(
        take(1),
        tap(([open, isLarge]) => {
          if (!isLarge && open) {
            this.toggle(false);
          }
        }),
      )
      .subscribe();
  }
}
