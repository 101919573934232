import { AsyncPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavItemComponent } from '@core/components/layout/nav-item/nav-item.component';
import { OrganizationSelectComponent } from '@core/components/organization-select/organization-select.component';
import { FeatureFlags } from '@core/feature-flags';
import { AmlDeskRoutes, amlDeskRoutes } from '@core/route-map';
import { RoleDisplayName } from '@core/types/grant.types';
import { environment } from '@env/environment';
import { TranslocoModule } from '@jsverse/transloco';
import { KycItemTypeCacheService } from '@kyc/kyc-item-type.cache.service';
import { KYCRoutes } from '@kyc/route-map-kyc';
import { WorkItemType } from '@kyc/types/kyc.types';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthActions, LogoutReason } from '@/auth/store/actions/auth.actions';
import { selectOwnGrants } from '@/auth/store/selectors/auth.selectors';
import { selectAuthTokensAndOwnGrant } from '@/auth/store/selectors/combined-auth.selectors';
import { LocationAnalysisRoutes } from '@/location-analysis/route-map-la';

type NavItemConfig = {
  labelKey: string;
  route: string;
  icon?: string;
  allowedRoles: RoleDisplayName[];
  subItems?: NavItemConfig[];
  condition?: () => boolean;
};

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  imports: [NavItemComponent, TranslocoModule, AsyncPipe, OrganizationSelectComponent],
})
export class NavComponent {
  featureFlags = FeatureFlags;

  ownGrant$ = this.store.select(selectOwnGrants);

  /**
   * Disable the navigation
   * Its true when the connection is offline
   * */
  @Input() disabled = false;

  @Input() iconsOnly = false;

  @Output() itemClicked = new EventEmitter<void>();

  readonly routes: AmlDeskRoutes = amlDeskRoutes;

  userRole: RoleDisplayName = RoleDisplayName.BasicUser;

  navigationItems: NavItemConfig[] = [
    {
      labelKey: 'navigation.dashboard',
      route: this.routes.dashboard,
      icon: 'cop-home',
      allowedRoles: [RoleDisplayName.RiskAnalysisCustomer],
    },
    {
      labelKey: 'navigation.transparency',
      route: this.routes.transparencyRegister,
      icon: 'cop-aml-registration',
      allowedRoles: [RoleDisplayName.RiskAnalysisCustomer],
    },
    {
      labelKey: 'navigation.riskAnalysis',
      route: this.routes.riskAnalysis,
      icon: 'cop-crosshair',
      allowedRoles: [RoleDisplayName.RiskAnalysisCustomer],
    },
    {
      labelKey: 'navigation.kycUserCreation',
      route: this.routes.kycUserCreation,
      icon: 'cop-kyc-check',
      allowedRoles: [RoleDisplayName.RiskAnalysisCustomer],
    },
    {
      labelKey: 'navigation.academy',
      route: this.routes.academy,
      icon: 'cop-academy',
      allowedRoles: [RoleDisplayName.RiskAnalysisCustomer],
    },
    {
      labelKey: 'kyc.navigation.introduction',
      route: this.routes.kyc,
      icon: 'cop-crosshair',
      allowedRoles: [RoleDisplayName.WorkItemUser],
      subItems: [
        {
          labelKey: 'kyc.navigation.create',
          route: this.getNavigationRoute(this.routes.kyc, KYCRoutes.create),
          icon: 'cop-edit',
          allowedRoles: [RoleDisplayName.WorkItemUser],
        },
        {
          labelKey: 'kyc.navigation.overview',
          icon: 'cop-eye-open',
          route: this.getNavigationRoute(this.routes.kyc, KYCRoutes.overview),
          allowedRoles: [RoleDisplayName.WorkItemUser],
        },
      ],
    },
    {
      labelKey: 'locationAnalysis.navigation.introduction',
      route: this.routes.locationAnalysis,
      icon: 'cop-map-marked',
      allowedRoles: [RoleDisplayName.LocationAnalysisUser],
      subItems: [
        {
          labelKey: 'locationAnalysis.navigation.create',
          route: this.getNavigationRoute(this.routes.locationAnalysis, LocationAnalysisRoutes.create),
          icon: 'cop-edit',
          allowedRoles: [RoleDisplayName.LocationAnalysisUser],
        },
      ],
    },
    {
      labelKey: 'navigation.documents',
      route: this.routes.documents,
      icon: 'cop-documents',
      allowedRoles: [RoleDisplayName.RiskAnalysisCustomer],
    },
    {
      labelKey: 'navigation.contact',
      route: this.routes.contact,
      icon: 'cop-contact-book',
      allowedRoles: [
        RoleDisplayName.BasicUser,
        RoleDisplayName.LocationAnalysisUser,
        RoleDisplayName.RiskAnalysisCustomer,
        RoleDisplayName.WorkItemUser,
      ],
    },
    {
      labelKey: 'navigation.frequentlyAskedQuestions',
      route: this.routes.faq,
      icon: 'cop-faq',
      allowedRoles: [RoleDisplayName.BasicUser, RoleDisplayName.RiskAnalysisCustomer],
    },
  ];

  userRoles$: Observable<RoleDisplayName[]> = this.store
    .select(selectOwnGrants)
    .pipe(map((ownGrants) => ownGrants!.map((grant) => grant.roleDisplayName)));

  workItemTypes$: Observable<WorkItemType[] | null> = this.kycItemTypeCacheService.workItemTypes$;

  navigationItems$: Observable<NavItemConfig[]> = combineLatest([
    of(this.navigationItems), // Base navigation items
    this.userRoles$, // User role
    this.workItemTypes$, // Work item types
  ]).pipe(
    map(([navigationItems, userRoles, workItemTypes]) => {
      // Filter navigation items based on a user role
      const filteredItems = navigationItems.filter(
        (item) => item.allowedRoles.some((role) => userRoles.includes(role)) && (!item.condition || item.condition()),
      );

      // Determine if any work item type includes 'ddt-' in its reference name
      const isDDWorkItemType = workItemTypes
        ? workItemTypes.some((item) => item.referenceName?.includes('ddt-'))
        : false;

      const kycNavItemIndex = filteredItems.findIndex((item) => item.labelKey === 'kyc.navigation.introduction');

      if (kycNavItemIndex === -1) {
        console.warn('KYC navigation item not found.');
      } else {
        filteredItems[kycNavItemIndex] = {
          ...filteredItems[kycNavItemIndex],
          labelKey: isDDWorkItemType ? 'navigationDD.title' : 'navigationKyc.title',
        };
      }

      return filteredItems;
    }),
  );

  public constructor(
    private readonly store: Store,
    private readonly kycItemTypeCacheService: KycItemTypeCacheService,
  ) {
    // TODO: prefer using the async pipe in the template
    this.store.select(selectAuthTokensAndOwnGrant()).subscribe(({ ownGrant }) => {
      if (ownGrant) {
        this.userRole = ownGrant.roleDisplayName;
      }
    });
  }

  /**
   * Handles changes in organization by updating the session grant and dispatching
   * an action to update the store with the new organization ID.
   *
   * @param {string} organizationId - The unique identifier of the organization.
   * @return {Promise<void>} A promise that resolves when the operation is complete.
   */
  public async onOrganizationChange(organizationId: string): Promise<void> {
    this.store.dispatch(AuthActions.setSessionGrant({ organizationId }));
  }

  /**
   * Function triggers Logout Action
   */
  public signOut(): void {
    if (this.disabled) {
      return;
    }
    this.store.dispatch(AuthActions.logout({ logoutReason: LogoutReason.Manual }));
  }

  /**
   * Handles the event when an item is clicked by emitting the `itemClicked` event.
   *
   * @return {void} Does not return any value.
   */
  public onItemClicked(): void {
    this.itemClicked.emit();
  }

  /**
   * Gets the navigation route based on the base and subSection provided.
   *
   * @param {string} base - The base URL for the navigation route.
   * @param {string} subSection - The subsection of the navigation route.
   * @return {string} The navigation route generated by combining the base and subSection.
   */
  public getNavigationRoute(base: string, subSection: string): string {
    return `${base}/${subSection}`;
  }

  protected readonly environment = environment;
}
